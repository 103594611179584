<template>
  <router-link :to="path" class="link_return"
               :class="active ? 'link_return--active' :
               black ? 'link_return--black' : ''">
    <slot>
      <svg-icon name="solid/arrow-left"/>
      <p>Show all regions</p>
    </slot>
  </router-link>
</template>

<script>
export default {
  props:{
    active: Boolean,
    black: Boolean,
    path: {
      type: String,
      default: ''
    },
  },
name: "LinkReturn"
}
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.link_return {
  display: inline-flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  height: 18px;
  cursor: pointer;
  color: #59B692;
  transition-duration: 0.2s;
  > ::v-deep p {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      bottom: 2px;
      left: 0px;
      border-bottom: 1px solid rgba(89, 182, 146, 0.5)
    }
  }
  &:hover {
    color: #3F9775;
    > ::v-deep p {
      &:before {
        border-bottom: 0
      }
    }
  }
  ::v-deep span {
    font-size: 12px;
    margin-right: 4px;
  }
  &--black {
    color: #1C2833;
    > ::v-deep p {
      &:before {
        border-bottom: 1px solid rgba(28, 40, 51, 0.3);
      }
    }
  }
  &--active {
    color: rgba(28, 40, 51, 0.8);
    > ::v-deep p:before {
      border-bottom: 0;
    }
  }
}
</style>
