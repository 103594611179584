<template>
  <transition name="simple_modal" >
    <div class="simple_modal__mask">
      <div class="simple_modal__wrapper" :class="closeText && 'simple_modal__wrapper--close_text'"
      >
        <div class="simple_modal__close_text" :class="closeTextReversed && 'simple_modal__close_text--reversed'">
          <LinkReturn @click="$emit('close')" >
            <svg-icon name="solid/arrow-left"/>
            <p>{{ closeText }}</p>
          </LinkReturn>
        </div>
        <div class="simple_modal__container" :class="closeOutside ? 'simple_modal__container--outside' : ''">
          <div class="simple_modal__close"
               :class="closeOutside ? 'simple_modal__close--outside' : ''"
               @click="$emit('close')">
            <svg-icon name="light/times"/>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import LinkReturn from "@/components/links/LinkReturn";

export default ({
  emits: ['close'],
  props: ['close-outside', 'closeText', 'closeTextReversed'],
  components: {
    LinkReturn
  }
})
</script>

<style scoped lang="scss">
@import "../../assets/scss/utils/vars";

.simple_modal {
  &__mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 40, 51, 0.6);
    display: table;
    transition: opacity 0.3s ease;
  }
  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  &__close_text {
    display: none;
  }
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 540px;
    min-height: 360px;
    margin: 0px auto;
    padding: 50px 32px 60px 44px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    &--outside {
      background-color: transparent;
      padding: 0;
      width: 855px;
      border-radius: 0px;
      box-shadow: none;
    }
  }
  &__close {
    position: absolute;
    z-index: 5;
    right: 25px;
    top: 15px;
    cursor: pointer;
    font-size: 30px;
    color: rgba(28, 40, 51, 0.5);
    transition-duration: 0.2s;

    &--outside {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: -40px;
      border-radius: 0px 2px 2px 0px;
      background-color: white;
    }
    &:hover {
      color: #1C2833;
    }
  }
}
@media (max-width: 768px) {
  .simple_modal {
    &__wrapper {
      vertical-align: top;
      &--close_text {
        .simple_modal {
          &__close_text {
            display: flex;
            align-items: center;
            background: $navy;
            height: 50px;
            padding: 0 20px;
            margin-bottom: -50px;
            font-weight: bold;
            a {
              font-size: 15px;
            }
            span {
              font-size: 14px;
              margin-right: 7px;
            }
            &--reversed {
              a {
                color: white;
                p:before {
                  border-bottom: 1px solid rgba(255,255,255, 0.5);
                }
              }
              span {
                color: white;
              }
              background: #59B692;
            }

          }
          &__close {
            display: none;
          }
        }
      }
    }
    &__container {
      width: calc(100% - 40px);
      max-height: calc(100vh - 50px);
      margin-top: 50px;
      overflow-y: auto;
      padding: 40px 20px 50px;
      &--outside {
        width: 100%;
        padding: 0;
      }
    }
    &__close {
      right: 20px;
      top: 15px;
      font-size: 24px;

      &--outside {
      }
    }
  }
}

</style>
